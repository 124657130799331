var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-select',{attrs:{"items":_vm.department,"item-text":"name","item-value":"selected","label":_vm.title,"rules":[_vm.selectedRules],"error":this.selectedChild && this.selectedChild.length > 0
          ? false
          : this.selectedChild
          ? true
          : this.selectedOrgs.length > 1
          ? true
          : false,"multiple":"","outlined":"","chips":"","return-object":""},on:{"click":_vm.handleOnclick},scopedSlots:_vm._u([(_vm.department.length > 0)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.department.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Select All")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null,{key:"selection",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [(index === 0 && _vm.isCompareArray)?_c('v-chip',[_c('span',[_vm._v(_vm._s('ALL'))])]):(!_vm.isCompareArray)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e()]}}],null,true),model:{value:(_vm.selectedChild),callback:function ($$v) {_vm.selectedChild=$$v},expression:"selectedChild"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }