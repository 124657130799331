<template>
  <v-row no-gutters>
    <v-col>
      <v-select
        v-model="selectedChild"
        :items="department"
        item-text="name"
        item-value="selected"
        :label="title"
        :rules="[selectedRules]"
        :error="
          this.selectedChild && this.selectedChild.length > 0
            ? false
            : this.selectedChild
            ? true
            : this.selectedOrgs.length > 1
            ? true
            : false
        "
        @click="handleOnclick"
        multiple
        outlined
        chips
        return-object
      >
        <template v-if="department.length > 0" v-slot:prepend-item>
          <v-list-item ripple @click="toggle">
            <v-list-item-action>
              <v-icon :color="department.length > 0 ? 'indigo darken-4' : ''">{{
                icon
              }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Select All</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        <!-- 
        <template v-slot:selection="{item, index}"></template> -->
        <template v-slot:selection="{item, index}">
          <v-chip v-if="index === 0 && isCompareArray">
            <span>{{ 'ALL' }}</span>
          </v-chip>
          <v-chip v-else-if="!isCompareArray">
            <span>{{ item.name }}</span>
          </v-chip>
        </template>
      </v-select>
      <!-- <p v-if="showWarning" style="color:#FF0000">
        This content is still shown on {{ partOfDepartment }}
      </p> -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'MultipleSelectedDepartment',
  data() {
    return {
      selectedChild:
        this.selectedDepartment && this.selectedDepartment.length > 0
          ? this.selectedDepartment
          : null,
      rules: this.rule,
      isCompareArray: true,
      showWarning: false
    }
  },
  props: {
    selectedOrgs: {type: Array},
    orgSelected: {type: String},
    selectedOrgIndex: {type: Number},
    selectedDepartment: {type: Array},
    department: {type: Array},
    title: {type: String},
    syncData: {type: Function},
    rule: {type: Function},
    isEditMode: {type: Boolean},
    isDisabled: {type: Boolean}
  },
  methods: {
    handleOnclick() {
      if (!this.selectedChild) {
        this.selectedChild = []
      }
    },
    compareArray() {
      const allDepartment = this.department.map(({id}) => id).sort()
      const allSelected = this.selectedChild.map(({id}) => id).sort()
      const sortSelected = allSelected
        .filter(value => allDepartment.includes(value))
        .sort()
      return JSON.stringify(allDepartment) === JSON.stringify(sortSelected)
    },
    selectedRules() {
      if (this.selectedChild && this.selectedChild.length > 0) {
        return true
      } else {
        if (this.selectedChild) {
          return 'Department is required'
        } else {
          if (this.selectedOrgs.length > 1) {
            return 'Department is required'
          } else {
            return true
          }
        }
      }
    },
    isWarn() {
      if (this.selectedChild && this.selectedChild.length > 0) {
        this.showWarning = !this.selectedSomeDepartment
        return true
      }
      this.showWarning = false
      return false
    },
    toggle() {
      this.$nextTick(() => {
        const allDepartment = this.department.map(({id}) => id)
        const selectedChild = this.selectedChild || []
        if (allDepartment.length === selectedChild.length) {
          // 100% all have
          this.selectedChild = []
        } else {
          // Dint select all
          this.selectedChild = [
            ...this.department.map(arr => ({
              id: arr.id,
              name: arr.name
            }))
          ]
        }
      })
    }
  },
  mounted() {
    if (this.selectedChild) {
      this.selectedChild = this.selectedDepartment
        .map(object =>
          object.selected
            ? {
                id: object.id,
                name: object.name
              }
            : null
        )
        .filter(v => v !== null)
      this.isCompareArray = this.compareArray(this.selectedDepartment)
    }
  },
  computed: {
    selectedAllDepartment() {
      const allDepartment = this.department.map(({id}) => id).sort()
      const selectedChild = this.selectedChild || []
      const sortSelected = selectedChild
        .filter(value => allDepartment.includes(value))
        .sort()
      return JSON.stringify(allDepartment) === JSON.stringify(sortSelected)
    },
    selectedSomeDepartment() {
      const allDepartments = this.department.map(({id}) => id)
      const selectedChild = this.selectedChild || []
      return (
        selectedChild.filter(value => allDepartments.includes(value)).length >=
        selectedChild.length
      )
    },
    compareSelectedDepartment() {
      const allDepartments = this.department.map(({id}) => id)
      const selectedChild = this.selectedChild || []
      return (
        selectedChild.filter(value => allDepartments.includes(value.id))
          .length === allDepartments.length
      )
    },
    partOfDepartment() {
      const allDepartments = this.department.map(({id}) => id)
      const selectedChild = this.selectedChild || []
      return selectedChild
        .filter(value => allDepartments.includes(value) === false)
        .toString()
    },
    icon() {
      // if (this.selectedAllDepartment) return 'mdi-close-box'
      if (this.compareSelectedDepartment) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  watch: {
    orgSelected() {
      this.selectedChild = []
      this.syncData(this.selectedChild)
    },
    selectedChild() {
      if (this.selectedChild) {
        this.syncData(this.selectedChild, this.selectedOrgIndex)
        this.isCompareArray = this.compareArray()
        this.isWarn()
      }
    },
    department() {
      if (this.department) {
        if (this.department.length > 0) {
          this.selectedChild = this.selectedDepartment
          this.syncData(this.selectedChild, this.selectedOrgIndex)
          this.isCompareArray = this.compareArray()
          this.isWarn()
        }
      }
    }
  }
}
</script>
<style scoped></style>
