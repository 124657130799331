<template>
  <v-container class="px-10" fluid>
    <!-- Breadcrumbs -->
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-diglog-confirm
      :show="isLeave"
      :subTitle="subTitle"
      :close="close"
      :buttonText="buttonConfirmText"
      :confirm="confirm"
      :buttonCancel="buttonCancel"
    />
    <v-popup-loading :isLoading="isLoading" />
    <v-alert-error :show="isError" :close="closeAlert"></v-alert-error>
    <v-alert-success :show="isSuccess" :close="closeAlert"></v-alert-success>
    <!-- Headline text -->
    <v-row>
      <v-col>
        <p class="headline">{{ textMode }} Coupon</p>
      </v-col>
    </v-row>

    <!-- Form -->
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-text-field
                label="Coupon Value"
                type="number"
                :onkeypress="chkDiscountType()"
                required
                :rules="PriceRules"
                v-model="couponObject.price"
                @change="fullpriceChange"
              ></v-text-field>
            </v-col>
            <v-radio-group
              v-model="couponObject.discount_type"
              row
              :mandatory="true"
              @change="updateDiscountType"
            >
              <v-radio key="0" value="0" label="THB"></v-radio>
              <v-radio key="1" value="1" label="%"></v-radio>
            </v-radio-group>
          </v-row>
          <v-row></v-row>
          <v-row>
            <v-col>Redeemption period</v-col>
          </v-row>
          <v-row>
            <v-col>
              <custom-date-picker
                label="Start From"
                v-model="couponObject.start_date"
                @input="startDateChange"
              ></custom-date-picker>
            </v-col>
            <v-col>
              <custom-date-picker
                label="End To"
                v-model="couponObject.end_date"
                :allowedDates="allowedDates"
              ></custom-date-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Quota"
                type="number"
                onkeypress="if(this.value.length==7) return false;"
                onkeydown="return event.keyCode !== 69"
                required
                :rules="QuotaRules"
                v-model="couponObject.quantity"
                @change="quotaChange"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>Status</v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group v-model="couponObject.status">
                <v-radio key="0" value="0" label="Active"></v-radio>
                <v-radio key="1" value="1" label="Inactive"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
        <v-preview
          :show="isPreview"
          :closePreivew="closePreview"
          :object="previewObject"
        />
        <v-col cols="6" class="text-right">
          <v-btn
            class="ma-2"
            large
            color="grayscale"
            v-show="this.isEditMode"
            @click="showPreview"
            >Preview</v-btn
          >
          <v-btn class="ma-2" large color="primary" @click="save">Save</v-btn>
        </v-col>
      </v-row>
      <div v-if="couponObject.orgs.length > 0">
        <v-row v-for="(item, index) in couponObject.orgs" :key="item.codename">
          <v-col cols="12" sm="5">
            <!-- <div class="text-input-org">
              <div class="text-org">{{ item.codename }}</div>
            </div> -->
            <v-select
              v-model="item.codename"
              :items="branchItems"
              :hide-selected="true"
              label="Branch *"
              outlined
              @input="event => handleBranchChange(event, index)"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="5" v-if="item.departments">
            <v-multiple-selected
              title="Department *"
              :selectedOrgs="couponObject.orgs ? couponObject.orgs : []"
              :selectedOrgIndex="index"
              :department="item.departments"
              :selectedDepartment="
                item.department_ids ? item.department_ids : []
              "
              :syncData="syncSelectedArrayData"
              :isEditMode="isEditMode"
              v-if="!isLoading"
            ></v-multiple-selected>
          </v-col>
          <v-col v-if="index !== 0">
            <v-icon
              medium
              class="px-2 mt-7"
              @click="removeFromSelecetedArray(index)"
              >delete</v-icon
            >
          </v-col>
        </v-row>
      </div>
      <!-- <v-row>
        <v-col cols="12" sm="5">
          <v-select
            v-model="orgFilterDepartment"
            :items="branchItems"
            :hide-selected="true"
            label="Branch *"
            outlined
            @input="handleNewBranchChange"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="5" v-if="departments">
          <v-multiple-selected
            title="Department *"
            :orgSelected="orgFilterDepartment"
            :department="departments"
            :selectedDepartment="selectionDepartment ? selectionDepartment : []"
            :syncData="syncSelectedData"
            :isEditMode="isEditMode"
            v-if="!isLoading"
          ></v-multiple-selected>
        </v-col>
        <v-col v-if="couponObject.orgs.length > 0">
          <v-icon medium class="px-2 mt-7" @click="removeFromSelecetedArray()"
            >delete</v-icon
          >
        </v-col>
      </v-row> -->
      <v-row class="mb-10">
        <v-col class="text-right">
          <v-btn
            class="ma-2"
            large
            color="primary"
            @click="addToSelecetedArray"
          >
            + Add Branch
          </v-btn>
        </v-col>
      </v-row>
      <!-- Contents -->
      <v-row>
        <!-- Thai -->
        <v-col>
          <v-card class="mx-auto">
            <v-card-title>THAI</v-card-title>

            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Title"
                    required
                    :rules="TitleRules"
                    v-model="couponObject.contents[0].name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-img
                    v-show="couponObject.contents[0].thumbnail"
                    :src="couponObject.contents[0].thumbnail"
                    aspect-ratio="4"
                    contain
                  ></v-img>

                  <v-file-input
                    ref="inputThaiRef"
                    prepend-icon
                    append-icon="mdi-camera"
                    label="Cover Image"
                    :rules="
                      this.isEditMode
                        ? [imageRule(couponObject.contents[0].thumbnail)]
                        : ImageRules
                    "
                    accept="image/png, image/jpeg, image/bmp"
                    :error="isImageThError"
                    :error-messages="imageThErrorMessage"
                    @change="updateImageThai"
                    @click:clear="updateImageThai"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <quill-editor
                    v-model="couponObject.contents[0].description"
                    :options="editorOption"
                  ></quill-editor>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <!-- English -->
        <v-col>
          <v-card class="mx-auto">
            <v-card-title>ENGLISH</v-card-title>

            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Title"
                    required
                    :rules="TitleRules"
                    v-model="couponObject.contents[1].name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-img
                    v-show="couponObject.contents[1].thumbnail"
                    :src="couponObject.contents[1].thumbnail"
                    aspect-ratio="4"
                    contain
                  ></v-img>
                  <v-file-input
                    ref="inputEngRef"
                    prepend-icon
                    append-icon="mdi-camera"
                    label="Cover Image"
                    :rules="
                      this.isEditMode
                        ? [imageRule(couponObject.contents[0].thumbnail)]
                        : ImageRules
                    "
                    :error="isImageEngError"
                    :error-messages="imageEngErrorMessage"
                    accept="image/png, image/jpeg, image/bmp"
                    @change="updateImageEng"
                    @click:clear="updateImageEng"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <quill-editor
                    v-model="couponObject.contents[1].description"
                    :options="editorOption"
                  ></quill-editor>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {convertFileToBase64} from '../../helper/helper'
import {mapState, mapActions} from 'vuex'
import {quillEditor, Quill} from 'vue-quill-editor'
import CustomDatePicker from '../../components/DatePicker'
import ImageUploader from 'quill-image-uploader'
import PopupLoading from '../../components/PopupLoading'
import AlertError from '../../components/AlertError'
import AlertSuccess from '../../components/AlertSuccess'
import {isUrl} from '../../helper/helper'
import Preview from '../../components/Preview'
import Confirm from '../../components/AlertConfirm'
import MultipleSelected from '../../components/MultipleSelectedDepartment.vue'
import MyLink from '../../constants/quill-link'
import moment from 'moment'
Quill.register('modules/imageUploader', ImageUploader)
Quill.register(MyLink)
export default {
  components: {
    'custom-date-picker': CustomDatePicker,
    'v-popup-loading': PopupLoading,
    'v-alert-error': AlertError,
    'v-alert-success': AlertSuccess,
    'v-preview': Preview,
    'v-diglog-confirm': Confirm,
    quillEditor,
    'v-multiple-selected': MultipleSelected
  },
  data() {
    return {
      max: 8,
      isError: false,
      isSuccess: false,
      isImageThError: false,
      imageThErrorMessage: '',
      buttonConfirmText: 'Leave',
      buttonCancel: 'Stay',
      isImageEngError: false,
      imageEngErrorMessage: '',
      subTitle:
        'You have changed the information.\nAre you sure to leave this page?',
      isPreview: false,
      previewItem: null,
      isSave: false,
      to: null,
      isLeave: false,
      showDialog: false,
      PriceRules: [
        v => !!v || 'Coupon Value is required',
        v => parseInt(v) > 0 || `Coupon Value be greater than 0`
      ],
      QuotaRules: [
        v => !!v || 'Quota is required',
        v => parseInt(v) > 0 || `Quota be greater than 0`
      ],
      TitleRules: [v => !!v || 'Title is required'],
      NameRules: [v => !!v || 'Name is required'],

      ImageRules: [
        value => !!value || 'Banner image is required',
        value =>
          !value ||
          value.size < 1040000 ||
          'Banner image size should be less than 1 MB!',
        value =>
          !value ||
          String(value.type).includes('image') ||
          'Only support image file!'
      ],
      isEditMode: false,
      editorOption: {
        modules: {
          imageUploader: {
            upload: file => {
              return new Promise(async resolve => {
                let base64 = await convertFileToBase64(file)
                let payload = {base64: base64, name: file.name}
                let url = await this.upload(payload)
                resolve(url)
              })
            }
          },
          toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            [{list: 'ordered'}, {list: 'bullet'}],
            ['link', 'image']
          ]
        }
      }
    }
  },
  destroyed() {
    this.isSuccess = true
    this.resetState()
  },
  beforeRouteLeave(to, from, next) {
    next()
  },
  async created() {
    this.isEditMode = !!this.$route.params.id
    this.setEditMode(this.isEditMode)
    await this.setOrgInfo()
    if (this.isEditMode) {
      await this.setCouponId(this.$route.params.id)
      // scroll to top
      window.scrollTo(0, 0)
      return
    } else {
      this.resetState()
    }
  },
  computed: {
    previewObject() {
      let previewObject = this.couponObject
      previewObject.content = this.couponObject.contents
      return previewObject
    },
    textMode() {
      return this.isEditMode ? 'Edit' : 'Create'
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', links: true, to: '/'},
        {text: 'Coupon', links: true, to: '/coupons'},
        {text: this.textMode, links: false}
      ]
    },
    branchItems: function() {
      const usedBranches = this.couponObject.orgs.map(object => object.codename)
      if (this.orgFilterDepartment) {
        usedBranches.push(this.orgFilterDepartment)
      }

      let branches = this.orgObject.map(brh => ({
        text: brh.codename,
        disabled: usedBranches.includes(brh.codename)
      }))
      return branches
    },
    ...mapState('coupon', [
      'couponObject',
      'isLoading',
      'orgObject',
      'departments',
      'selectionDepartment',
      'orgFilterDepartment'
    ])
  },

  methods: {
    removeFromSelecetedArray(index) {
      this.couponObject.orgs.splice(index, 1)
    },
    addToSelecetedArray() {
      if (
        this.couponObject.orgs[this.couponObject.orgs.length - 1].department_ids
          .length > 0
      ) {
        this.setSelectedArray()
      } else {
        this.isError = true
      }
    },
    handleBranchChange(event, index) {
      this.couponObject.orgs[index].codename = event
      this.couponObject.orgs[index].department_ids = []
      this.couponObject.orgs[index].departments = []
      this.fetchDepartmentToReplace({
        index,
        codename: event
      })
    },
    chkDiscountType() {
      if (this.couponObject.discount_type == 0) {
        return 'if(this.value.length==7) return false;'
      } else {
        return 'if(this.value.length==2) return false;'
      }
    },
    updateDiscountType() {
      this.couponObject.price = ''
    },
    setLimitNumber(event) {
      if (event) {
        this.couponObject.end_date = ''
      }
    },
    startDateChange(event) {
      if (event) {
        this.couponObject.end_date = ''
      }
    },
    discountChange(event) {
      if (event != '' && Number.isInteger(parseInt(event))) {
        this.couponObject.discount = Math.floor(parseInt(event))
      }
    },
    fullpriceChange(event) {
      if (event != '' && Number.isInteger(parseInt(event))) {
        this.couponObject.price = Math.floor(parseInt(event))
      }
    },
    quotaChange(event) {
      if (event != '' && Number.isInteger(parseInt(event))) {
        this.couponObject.quantity = Math.floor(parseInt(event))
      }
    },
    close() {
      this.isLeave = false
      this.to = null
    },
    confirm() {
      this.isLeave = false
      this.$router.push(this.to)
    },
    allowedDates(val) {
      return moment(this.couponObject.start_date).isSameOrBefore(moment(val))
    },
    showPreview() {
      this.setStyleImage()
      this.isPreview = true
    },
    closePreview() {
      this.isPreview = false
    },
    closeAlert() {
      this.isError = false
      this.isSuccess = false
    },
    async save() {
      if (this.validate()) {
        this.isSave = true
        this.setStyleImage()
        if (!this.isEditMode) {
          let status = await this.create(this.couponObject)
          if (status) {
            this.$refs.form.resetValidation()
            this.$refs.inputThaiRef.clearableCallback()
            this.$refs.inputEngRef.clearableCallback()

            return (this.isSuccess = true)
          }
          return (this.isError = true)
        }
        if (this.isEditMode) {
          const payload = {pkg: this.couponObject, id: this.$route.params.id}
          let status = await this.updatePkg(payload)
          if (status) {
            return (this.isSuccess = true)
          }
          return (this.isError = true)
        }
      } else {
        this.isError = true
      }
    },
    imageRule(val) {
      if (this.isEditMode) {
        if (isUrl(val)) {
          return true
        } else {
          if (val != '') {
            return true
          }
          return 'Picture is required'
        }
      } else {
        if (!val || val.size > 1040000) {
          return 'Picture size should be less than 1 MB!'
        }
        if (!val || !String(val.size).includes('image')) {
          return 'Only support image file!'
        } else return true
      }
    },
    updateImageThai(file) {
      if (file && file.name) {
        if (this.isEditMode) {
          if (!String(file.type).includes('image')) {
            this.isImageThError = true
            this.imageThErrorMessage = 'Only support image file!'
            return
          } else if (!file || file.size > 1000000) {
            this.isImageThError = true
            this.imageThErrorMessage = 'Picture size should be less than 1 MB!'
            return
          } else {
            this.setImageThai(file)
            this.isImageThError = false
            this.imageThErrorMessage = ''
            return
          }
        } else {
          this.setImageThai(file)
        }
      } else {
        this.couponObject.contents[0].thumbnail = ''
      }
    },
    updateImageEng(file) {
      if (file && file.name) {
        if (this.isEditMode) {
          if (!String(file.type).includes('image')) {
            this.isImageEngError = true
            this.imageEngErrorMessage = 'Only support image file!'
            return
          } else if (!file || file.size > 1000000) {
            this.isImageEngError = true
            this.imageEngErrorMessage = 'picture size should be less than 1 MB!'
            return
          } else {
            this.setImageEng(file)
            this.isImageEngError = false
            this.imageEngErrorMessage = ''
            return
          }
        } else {
          this.setImageEng(file)
        }
      } else {
        this.couponObject.contents[1].thumbnail = ''
      }
    },
    syncSelectedArrayData(data, index) {
      let results = data.map(object => {
        let data = {}
        data.id = object.id
        data.name = object.name
        data.selected = true
        return data
      })
      this.couponObject.orgs[index]
      this.couponObject.orgs[index].department_ids = results
    },

    validate() {
      if (this.$refs.form.validate()) {
        return true
      }
    },

    ...mapActions('coupon', [
      'setSelectedArray',
      'setEditMode',
      'setStyleImage',
      'setCouponId',
      'create',
      'resetState',
      'setImageThai',
      'setEditMode',
      'upload',
      'setImageEng',
      'updatePkg',
      'setOrgInfo',
      'setSmallLoading',
      'fetchDepartmentToReplace'
    ])
  }
}
</script>

<style scoped>
.v-form .col,
.container .col {
  padding-top: 0;
  padding-bottom: 0;
}
.text-input-org {
  padding: 0 12px;
  border-radius: 6px;
  min-height: 68px;
  border: 1px solid rgba(37, 37, 37, 0.5);
}
.text-org {
  font-size: 16px;
  letter-spacing: normal;
  padding: 19px 12px;
}
</style>
